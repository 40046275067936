@media only screen and (max-width: 768px) {
  .loginbg {
    background-image: none;
    background-size: 77%;
    min-height: 105vh !important;
    height: 105vh !important;
    max-height: 105vh !important;
  }

}
