.warranty-more-divider {
  margin: 0;
  &.parent-divider {
    background-color: orange;
    height: 4px;
    position: relative;
    top: -2px;
    z-index: 5;
  }
  &.child-divider {
    background-color: blue;
  }
}

.warranty-more-spacer {
  display: inline-block;
  margin-left: 2em;
}

.warranty-more-list-item {
  font-size: 12px;
}
