.ViewImage-modal-close-btn {
  &.ant-btn {
    background: transparent !important;
    color: gray;
  }
  position: absolute;
  background: transparent !important;
  top: 10px;
  right: 5px;
  z-index: 100;
}

.ViewImage-modal-image {
  width: 100%;
}
