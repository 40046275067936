@media only screen and (max-width: 600px) {
  .loginbg {
    background-size: 77%;
    min-height: 105vh !important;
    height: 105vh !important;
    max-height: 105vh !important;
  }

  .features {
    display: none;
  }
  .dimotitle {
    color: #000;
  }
  .login-card {
    box-shadow: 0 10px 25px grey;
    padding: 0;
  }

  .login-form {
    margin-left: 25px;
    margin-right: 0;
  }

  .dimotitle {
    display: none;
  }

  .loginTitle {
    margin-right: 0;
  }

  .btn-margin{
    margin-top: 5px !important;
  }

  .AppShell-header.ant-layout-header h2 {
    display: none;
  }
}
