.AppShell-sider {
  /* position: fixed; */
  width: 100%;
  height: 100vh;
  z-index: 100;
  overflow: hidden;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  border: none;
}

.AppShell-content.ant-layout-content {
  height: 100%;
  width: 100%;
  background-image: url("../../../Assests/Images/5656.png") !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  padding: 15px;
}

.AppShell-logo {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
}

.loading-anim {
  background-repeat: no-repeat;
  background-position: center;
  width: 50%;
  padding-left: 35%;
}

.login-card {
  box-shadow: 0 10px 25px grey;
  padding: 20px;
}
.login-card2 {
  box-shadow: 0 10px 25px grey;
  padding: 20px;
  text-align: center;
}
.loginbg {
  /* background-repeat: no-repeat;
  background-image: url("../../../Assests/Images/bg1.png");
  background-position: center; */
  background-size: 77.5% !important;
  min-height: 105vh !important;
  height: 105vh !important;
  max-height: 105vh !important;
}

.AppShell-sider.ant-layout-sider-collapsed .AppShell-logo {
  background-size: 70%;
}

.ant-select-selection--single {
  pointer-events: none;
}

.btn-margin {
  margin-top: 40px !important;
}

// aside.ant-layout-sider-collapsed .ant-layout-sider-children {
//   width: 80px !important;
// }

.warrantyLink {
  animation: blinker 1s linear infinite;
  font-size: medium;
  text-decoration: underline;
  color: red;
  font-weight: bold;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.AppShell-sider .ant-layout-sider-children {
  position: fixed;
  width: 200px;
  transition: all 0.3s ease;
}

.AppShell-side-menu.ant-menu-inline {
  border: none;
}

.AppShell-sider.ant-layout-sider-collapsed .ant-layout-sider-children {
  width: 80px;
}

.AppShell-header.ant-layout-header {
  background: #392f78;
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  position: fixed;
  color: #fff;
  z-index: 1000;
}

.AppShell-header.ant-layout-header.AppShell-header-collapsed {
  .Appshell-hedader-menu {
    margin-right: 60px;
  }
}

// .Appshell-hedader-filler {
//   height: 64px;
//   width: 200px;
//   background: #392f78;
//   overflow: hidden;
//   position: absolute;
//   right: -200px;
// }

.Appshell-hedader-menu {
  margin-right: 180px;
}

.AppShell-header.ant-layout-header h2 {
  color: #fff;
  font-weight: bold;
}

.ant-btn {
  background: #392f78 !important;
}

.AppShell-side-menu span {
  color: #392f78 !important;
  font-size: 14px;
}

.AppShell-header.ant-layout-header .trigger:hover {
  color: #fff;
}

.AppShell-header.ant-layout-header .anticon-menu-unfold {
  margin-left: -50px;
}

.AppShell-header.ant-layout-header .anticon-menu-fold {
  margin-left: -50px;
}

.AppShell-header.ant-layout-header .anticon-menu-unfold:hover {
  background: rgba(0, 0, 0, 0.3);
}

.AppShell-header.ant-layout-header .anticon-menu-fold:hover {
  background: rgba(0, 0, 0, 0.3);
}

.Appshell-hedader-menu {
  background: transparent;
  border: none;
}

.Appshell-hedader-menu .ant-menu-item {
  color: #fff;
  border: none !important;
  padding: 0;
}

.avatar {
  margin-right: 0;
}

/* .ant-layout-sider-children .logo {
  height: 64px;
  margin: 0;
  border-bottom: 1px solid #f0f2f5;
} */

.AppShell-side-menu {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.AppShell-trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.AppShell-page-header {
  border: none;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}

.AppShell-hidden .AppShell-sider,
.AppShell-hidden .AppShell-header,
.AppShell-hidden .AppShell-page-header {
  display: none;
}

.AppShell-hidden .AppShell-content {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}

.Appshell-active-link {
  background: inherit;
}

.formcol {
  margin: 0px 15px;
}

.form_separtion {
  background-color: #392f78;
  margin-bottom: 15px;
  margin-top: 10px;
  text-transform: uppercase;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

th {
  background-color: #0090c2 !important;
  color: #fff !important;
}

.body-container {
  padding-left: 100px;
}

.AppShell-hidden .body-container {
  padding-left: 0px;
}

.formTitle {
  text-align: right !important;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.lineSeparotor {
  border: 1px solid yellow;
}

.valign {
  height: 100vh;
  height: 100%;
  vertical-align: middle;
}

.logoImage {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  height: 65px;
  min-height: 65px;
  max-height: 65px;
  position: absolute;
  left: 14px;
  display: inline-block;
  padding-top: 5px;
}

.loginTitle {
  margin-right: 25px;
  color: #392f78;
}

.promotion-ad {
  display: flex;
  justify-content: right;
}

.ant-view-table thead > tr > th {
  display: none;
}

.ant-view-table-warranty thead > tr > th {
  display: none;
}

.ant-view-table tr:nth-child(1) td {
  border: none !important;
  background-color: #fff;
}

.ant-view-table tr:nth-child(even) {
  background-color: #f2f2f2;
  border-left: 1px solid #f2f2f2 !important;
}
.ant-view-table tr:nth-child(odd) {
  background-color: #f2f2f2;
  border-left: 1px solid #f2f2f2 !important;
}

.ant-view-table tr:nth-child(6) td {
  border: none !important;
  background-color: #fff;
}
.ant-view-table tr:nth-child(11) td {
  border: none !important;
  background-color: #fff;
}

.ant-view-table-warranty tr:nth-child(1) td {
  border: none !important;
  background-color: #fff;
}

.ant-view-table-warranty tr:nth-child(even) {
  background-color: #f2f2f2;
  border-left: 1px solid #f2f2f2 !important;
}
.ant-view-table-warranty tr:nth-child(odd) {
  background-color: #f2f2f2;
  border-left: 1px solid #f2f2f2 !important;
}

.ant-view-table-warranty tr:nth-child(8) td {
  border: none !important;
  background-color: #fff;
}
.ant-view-table-warranty tr:nth-child(2) td {
  font-weight: bold;
}
.ant-view-table-warranty tr:nth-child(12) td {
  border: none !important;
  background-color: #fff;
}

.add-user-group {
  padding: 5px !important;
}

.dimotitle {
  width: 440px;
  display: inline-block;
  min-width: 440px;
  max-width: 440px;
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  position: absolute;
  padding-top: 18px;
  right: 14px;
  color: #fff;
}

.backTopImage {
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.topBar {
  background-image: linear-gradient(#382e78, #3e2ea6);
  margin-bottom: 1%;
  min-height: 75px;
}

.heading {
  width: 100%;
  text-align: center;
}

.vl {
  background-repeat: no-repeat;
  background-image: url("../../../Assests/Images/vertical_divider_png_1459678.png") !important;
  background-position: center;
  height: 400px;
}

.login-form-forgot {
  color: #392f78;
}

.heading h1 {
  color: #392f78;
}

.loginSep {
  align-items: center;
  padding: 25px;
}
.loginSep2 {
  text-align: center;
  padding: 25px;
}
.login-form-button {
  text-align: right;
}

.iconimg {
  width: 25px;
  margin-left: -55px;
}

.inputLogin {
  display: inline;
}

.features {
  padding: 30px;
}

.viewTable {
  font-size: 20px;
  width: 100%;
}

.viewTable tr td {
  width: 50%;
  padding: 5px;
}

.inlineCol {
  margin-left: 0px;
  margin-right: 25px;
  left: 0px;
}

.submit-btn {
  background-color: #03a82a !important;
}

.leftPad {
  padding-left: 10px;
}

.table-container {
  max-width: 1300px;
  overflow-x: auto;
}

.btn-search {
  border-style: 0px solid #fff !important;
}

@media only screen and (max-width: 992px) {
  .AppShell-sider.ant-layout-sider.ant-layout-sider-light {
    position: absolute !important;
  }
  .AppShell-sider.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed {
    flex: 0 0 0 !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
  }
  .AppShell-side-menu.ant-menu-light.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
    width: 0px !important;
  }
  .Appshell-hedader-menu {
    margin-right: -24px !important;
  }
  .AppShell-header.ant-layout-header h2 {
    overflow: hidden;
  }
}
