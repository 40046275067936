.ViewWarrantyDetails-view-more-btn {
  position: relative;
  bottom: -50px;
  z-index: 2;
  width: 100%;
}

.ViewWarrantyDetails-view-image-btn {
  position: relative;
  z-index: 2;
  width: 100%;
}
