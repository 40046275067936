.Product-upload-form-card {
  margin-top: 16px;

  .ProductUploadForm-file-upload-btn {
    color: #fff;
  }

  .ProductUploadForm-save-btn {
    margin-top: 16px;
  }

  .ProductUploadForm-upload-error {
    color: #f5222d;
  }
}
