.AddWarrantyForm-img-preview {
  width: 100%;
  overflow: hidden;
  height: 300px;
}

form.AddWarrantyForm {
  .AddWarrantyForm-serialvalid-msg {
    color: #f5222d;
  }
}
